exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-ricette-js": () => import("./../../../src/pages/ricette.js" /* webpackChunkName: "component---src-pages-ricette-js" */),
  "component---src-pages-simple-search-js": () => import("./../../../src/pages/simpleSearch.js" /* webpackChunkName: "component---src-pages-simple-search-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-index-en-template-js": () => import("./../../../src/templates/index-en-template.js" /* webpackChunkName: "component---src-templates-index-en-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-post-en-template-js": () => import("./../../../src/templates/post-en-template.js" /* webpackChunkName: "component---src-templates-post-en-template-js" */),
  "component---src-templates-post-it-template-js": () => import("./../../../src/templates/post-it-template.js" /* webpackChunkName: "component---src-templates-post-it-template-js" */),
  "component---src-templates-tags-en-template-js": () => import("./../../../src/templates/tags-en-template.js" /* webpackChunkName: "component---src-templates-tags-en-template-js" */),
  "component---src-templates-tags-it-template-js": () => import("./../../../src/templates/tags-it-template.js" /* webpackChunkName: "component---src-templates-tags-it-template-js" */)
}

